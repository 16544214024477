import throttle from "lodash.throttle";

export const LAST_ACTIVITY_KEY = "lastActivityTimestamp";
const INACTIVITY_TIMEOUT_ID = "inactivity-timeout-id";
const LOGOUT_CHANNEL_NAME = "inactivity-logout-channel";
const LOGOUT_MESSAGE = "LOGOUT";
const USER_EVENTS = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
];

/**
 * Sets up inactivity logout functionality.
 * - if none of the USER_EVENTS are fired within ${timeoutMinutes} minutes, the user is logged out
 * - if the user has multiple tabs open, activity in one tab will keep the user logged in
 *
 * (based on pi-web/src/utils/inactivityLogout.ts)
 *
 * @param {number} options.timeoutMinutes - The timeout duration in minutes (if 0, this fn exits)
 * @param {Function} options.onTimeout - The callback function to execute on timeout.
 */
export function setupInactivityLogout({ timeoutMinutes, onTimeout }) {
  const channel = new BroadcastChannel(LOGOUT_CHANNEL_NAME);

  const throttledEventHandler = throttle(() => resetTimer(), 1000, {
    leading: true,
    trailing: false,
  });
  cleanup();
  if (!timeoutMinutes) return;

  // Setup timer, event listeners
  if (!checkIfTimedOut() && timeoutMinutes > 0) {
    resetTimer();
    window.addEventListener("storage", handleStorageChange);
    channel.addEventListener("message", handleBroadcastMessage);
    USER_EVENTS.forEach((event) => {
      window.addEventListener(event, throttledEventHandler);
    });
  }

  /**
   * Cleanup the timer and event listeners.
   */
  function cleanup() {
    const timeoutId = localStorage.getItem(INACTIVITY_TIMEOUT_ID);
    if (timeoutId) {
      clearTimeout(timeoutId);
      localStorage.removeItem(INACTIVITY_TIMEOUT_ID);
    }
    window.removeEventListener("storage", handleStorageChange);
    USER_EVENTS.forEach((event) => {
      window.removeEventListener(event, throttledEventHandler);
    });
    localStorage.removeItem(LAST_ACTIVITY_KEY);
  }

  /**
   * Cleanup the timer, broadcast the logout message, and call the onTimeout callback.
   */
  function handleTimeout() {
    cleanup();
    const channel = new BroadcastChannel(LOGOUT_CHANNEL_NAME);
    channel.postMessage({ type: LOGOUT_MESSAGE });
    channel.close();
    onTimeout();
  }

  /**
   * Check if the last user interaction was more than the timeout duration ago.
   *
   * @returns {boolean} - Whether the user has timed out.
   */
  function checkIfTimedOut() {
    const lastActivity = Number(
      localStorage.getItem(LAST_ACTIVITY_KEY) || Date.now()
    );
    const timeSinceLastActivity = Date.now() - lastActivity;
    const timeoutDuration = timeoutMinutes * 60 * 1000;

    if (timeSinceLastActivity >= timeoutDuration) {
      handleTimeout();
      return true;
    }
    return false;
  }

  /**
   * Reset the timer (to check if the user has timed out in ${timeoutMinutes} minutes).
   */
  function resetTimer() {
    let timeoutId = localStorage.getItem(INACTIVITY_TIMEOUT_ID);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());

    if (timeoutMinutes > 0) {
      timeoutId = setTimeout(handleTimeout, timeoutMinutes * 60 * 1000);
      localStorage.setItem(INACTIVITY_TIMEOUT_ID, timeoutId);
    } else {
      localStorage.removeItem(INACTIVITY_TIMEOUT_ID);
    }

    localStorage.setItem;
  }

  /**
   * When the user interacts with the app in another tab, reset the timer
   * (ie. keep the user active)
   */
  function handleStorageChange(event) {
    if (event.key === LAST_ACTIVITY_KEY && !checkIfTimedOut()) {
      resetTimer();
    }
  }

  /**
   * When the 'logout' message is received, forcibly logout the user.
   */
  function handleBroadcastMessage(event) {
    if (event.data.type === LOGOUT_MESSAGE) {
      cleanup();
      channel.close();
      onTimeout();
    }
  }
}

import AuthService from "@/services/auth.service";
import { reloadOnMissingChunks } from "@/utils";
import { authAPIUrl } from "@/environment";
import { captureMessageWithContext } from "@/sentry";

const ProcurementPage = () =>
  import(
    /* webpackChunkName: "ProcurementPage" */ "@/pages/ProcurementPage"
  ).catch(reloadOnMissingChunks);
const LicensesPaidPage = () =>
  import(
    /* webpackChunkName: "LicensesPaidPage" */ "@/pages/LicensesPaidPage"
  ).catch(reloadOnMissingChunks);
const RegisterPage = () =>
  import(/* webpackChunkName: "RegisterPage" */ "@/pages/RegisterPage").catch(
    reloadOnMissingChunks
  );
const JumpRegisterPage = () =>
  import(
    /* webpackChunkName: "JumpRegisterPage" */ "@/pages/JumpRegisterPage"
  ).catch(reloadOnMissingChunks);
const SetupPage = () =>
  import(/* webpackChunkName: "SetupPage" */ "@/pages/SetupPage").catch(
    reloadOnMissingChunks
  );
const TrainingPage = () =>
  import(/* webpackChunkName: "TrainingPage" */ "@/pages/TrainingPage").catch(
    reloadOnMissingChunks
  );

async function loginWithFusionAuth(next) {
  try {
    var res = await AuthService.isAuthenticated();
  } catch (error) {
    if (error.response?.data?.error?.code === "TOKEN_EXPIRED") {
      // Token expired with expected error code, handle it
      try {
        await AuthService.refreshToken();
        return next({ name: "safepiplannings" });
      } catch (refreshError) {
        return redirectAfterError(next);
      }
    }
    return redirectAfterError(next);
  }

  if (res?.data?.success) {
    return next({ name: "safepiplannings" });
  }

  void captureMessageWithContext(
    "Unexpected response when checking if user is authenticated",
    { responseURL: res?.request?.responseURL, response: res?.data }
  );
  return redirectAfterError(next);
}

function redirectAfterError(next) {
  window.location.assign(authAPIUrl + "/auth/login?type=admin");
  next(false);
}

export function getPublicPagesRoutes() {
  return [
    {
      path: "/login",
      alias: "/",
      name: "login",
      beforeEnter(_to, _from, next) {
        loginWithFusionAuth(next);
      },
    },
    {
      path: "/register",
      name: "register",
      component: RegisterPage,
      meta: { isCloud: true, noHeader: true, hideMenu: true },
    },
    {
      path: "/onprem-register",
      name: "onprem-register",
      component: RegisterPage,
      meta: { onPrem: true, noHeader: true, hideMenu: true },
    },
    {
      path: "/jump-register",
      name: "jump-register",
      component: JumpRegisterPage,
      meta: { isCloud: true, noHeader: true, hideMenu: true },
    },
    {
      path: "/remotetraining",
      name: "remotetraining",
      component: TrainingPage,
      meta: { isCloud: true, noHeader: true, hideMenu: true },
    },
    {
      path: "/setup",
      name: "setup",
      async beforeEnter(_to, _from, next) {
        const isAllowed = await AuthService.canSetup();
        next(isAllowed ? undefined : "/");
      },
      component: SetupPage,
      meta: { isCloud: false, noHeader: true, hideMenu: true },
    },
    {
      path: "/procurement",
      name: "procurement",
      component: ProcurementPage,
      meta: { hideMenu: true },
    },
    {
      path: "/payed",
      name: "payed",
      component: LicensesPaidPage,
      meta: { hideMenu: true },
    },
  ];
}

export function sortStickies(stickies, origin) {
  let mirror = stickies.filter((s) => s.origin_board_type !== origin);
  mirror.sort((a, b) => a.name.localeCompare(b.name));
  let origins = stickies.filter((s) => s.origin_board_type === origin);
  origins.sort((a, b) => (a.index || 0) - (b.index || 0));
  return origins.concat(mirror);
}

export function saveLocalSettings(settings) {
  if (typeof Storage !== "undefined") {
    let existingSettings = localStorage.getItem("settings");
    existingSettings = existingSettings ? JSON.parse(existingSettings) : {};

    for (const key in settings) {
      existingSettings[key] = settings[key];
    }

    localStorage.setItem("settings", JSON.stringify(existingSettings));
  }
}

import axios from "axios";
import { v4 as uuid } from "uuid";
import { captureMessageWithContext } from "@/sentry";
import { authAPIUrl } from "@/environment";

let refreshing = null;

export function createApiClient(baseUrl, options) {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    transformRequest,
    ...options,
  });

  return setupResponseInterceptor(authenticationInterceptor(client));
}

export function createOldApiClient(baseUrl, options) {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    transformRequest,
    ...options,
  });

  return authenticationInterceptor(client);
}

function transformRequest(data, headers) {
  if (headers) {
    headers["common"]["correlation-id"] = uuid();
  }
  for (const transform of axios.defaults.transformRequest) {
    data = transform(data, headers);
  }
  return data;
}

const rawClient = axios.create({
  baseURL: authAPIUrl,
  withCredentials: true,
  timeout: 10000,
  transformRequest,
});

function setupResponseInterceptor(client) {
  client.interceptors.response.use(
    (response) => {
      const data = response.data;
      if (
        data.success === false ||
        ("status" in data && data.status !== "success")
      ) {
        throw data.error;
      }
      return response;
    },
    (error) => {
      void captureMessageWithContext("unsuccessful request", {
        request: error.config.url,
        response: {
          data: error.response?.data,
          status: error.response?.status,
        },
      });

      if (error.response?.data?.status === "failed") {
        const data = error.response?.data;
        throw {
          data,
          code: error.code,
          config: error.config,
          error:
            data?.error?.message ||
            data?.message ||
            error.message ||
            "Could not execute this action",
          errorCode: data?.error?.code || data?.code || error.code,
        };
      }

      if (error.response?.data?.error) {
        throw error.response.data.error;
      }
      throw "Could not execute this action";
    }
  );

  return client;
}

export async function handleRequest(
  apiCall,
  dataExtractor = (rsp) => rsp.data.data
) {
  const rsp = await apiCall();

  if (rsp.data.status === "success") {
    return { success: true, data: dataExtractor(rsp) };
  }

  // If `createApiClient` returned an error object, return it
  if (rsp.error || rsp.errorCode) {
    return {
      success: false,
      error: rsp.error,
      errorCode: rsp.errorCode,
    };
  }

  // Let it fail implicitly
}

function authenticationInterceptor(client) {
  function redirectToLogin() {
    window.location.assign(authAPIUrl + "/auth/login?type=admin");
    throw new Error("Unauthorized");
  }

  // using createApiClient for refreshToken could lead to endless loop
  async function refreshToken() {
    const res = await rawClient.get(
      "auth/token/refresh?type=admin&path=safepiplannings"
    );
    return res.data;
  }

  client.interceptors.response.use(
    (response) => response,
    async (fail) => {
      if (fail.response?.status === 401) {
        const data = fail.response.data;
        switch (data?.error?.code) {
          case "UNAUTHENTICATED":
            // This happens primarily when the user is yet logged in at all (e.g. initial of the app)
            return redirectToLogin();
          case "TOKEN_EXPIRED":
            // if we already are refreshing the token, don't refresh again,
            // but wait for the refresh to end before repeating the request
            if (!refreshing) {
              refreshing = refreshToken();
            }
            try {
              await refreshing;
            } catch (err) {
              // Token refresh failed; redirect user to login page
              redirectToLogin();
            } finally {
              refreshing = null;
            }
            return await rawClient.request(fail.config);
        }
        // If for some reason a 401 does not contain an error-code
        return redirectToLogin();
      }
      throw fail;
    }
  );

  return client;
}

import { createApiClient, handleRequest } from "@/services/apiClient";

const baseLicenseUrl = "v1/license";

export class LicenseService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getLicense() {
    return handleRequest(() => this.apiClient.get(baseLicenseUrl));
  }

  async getOnPremKey() {
    return handleRequest(
      () => this.apiClient.get(`${baseLicenseUrl}/onprem-key`),
      (rsp) => rsp.data.data.key
    );
  }

  async updateOnPremKey(key) {
    return handleRequest(() =>
      this.apiClient.patch(`${baseLicenseUrl}/onprem-key`, { key })
    );
  }
}

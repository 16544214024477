import {
  captureExceptionWithContext,
  captureMessageWithContext,
} from "@/sentry";

const CommonData = {};

CommonData.install = function (Vue) {
  Vue.prototype.$recaptchaKey = "6LeWkjQUAAAAAIFgzRm2gJMOMBxek8h6ozwuelbT";

  Vue.prototype.$PIAPIVersion = "6";

  Vue.prototype.$almPaths = {
    jira: {
      path: "edit-jira-alm-tool",
      queryPath: "jiraConnection",
    },
    ac: {
      path: "edit-ac-alm-tool",
      queryPath: "acConnection",
    },
    ado: {
      path: "edit-ado-alm-tool",
      queryPath: "adoConnection",
    },
  };

  Vue.prototype.$getBase = function () {
    return (
      "ch.rentouch.piplanning." +
      this.$PIAPIVersion +
      ".room." +
      this.$store.getters.company
    );
  };
  Vue.prototype.$getALMBase = function () {
    return this.$getBase() + ".alm";
  };

  Vue.prototype.$runFnToCall = function (fn, argumentsArray = []) {
    if (this.$store.state.wsconnection.isOpen) {
      const res = fn(this.$store.state.wsconnection.session, ...argumentsArray);
      if (!res || !res.catch) {
        return res;
      }
      return res.catch((e) => {
        void captureExceptionWithContext(e, { fn, argumentsArray });
      });
    } else {
      void captureMessageWithContext(
        "Connection interrupted, try to reload the page"
      );
    }
  };
};

export default CommonData;

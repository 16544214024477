import { captureExceptionWithContext } from "@/sentry";

export function almHandlerJira(remote, connectionID, almSessionId) {
  function getIssueTypes(projectId) {
    return projectId === -1
      ? Promise.resolve([])
      : remote
          .call("get_issue_types", [connectionID], {
            project_id: projectId,
          })
          .then((almIssueTypes) => {
            if (Array.isArray(almIssueTypes)) {
              return almIssueTypes.map((almIssueType) => ({
                id: almIssueType.id,
                name: almIssueType.name,
              }));
            } else {
              return [];
            }
          });
  }

  return {
    name: "Jira",
    supportsLinks: true,
    checkStatus: () => {
      return remote
        .call("check_all_project_permissions", [], {
          connection_id: connectionID,
        })
        .then((res) => ({
          working: res.errored_teams.length === 0,
        }));
    },
    getIssueLinkTypes: () => {
      return remote
        .call("get_issue_link_types", [connectionID])
        .then((links) => {
          const types = links.issueLinkTypes.map((l) => ({
            link_type: "issue_link",
            issue_link_id: l.id,
            name: l.inward,
          }));
          types.unshift({
            link_type: "epic",
            issue_link_id: null,
            name: "links epic to\n(by epic link field)",
          });
          types.unshift({
            link_type: "parent",
            issue_link_id: null,
            name: "is parent of\n(by parent link field)",
          });
          return types;
        });
    },
    getIssueFields(project_id, issue_type_id) {
      return remote.call("get_fields", [connectionID], {
        project_id,
        issue_type_id,
      });
    },
    async getFilteredIssueFields(project_id, issue_type_id) {
      const simpleTypes = ["option", "version", "component"];
      const arrayTypes = ["string", ...simpleTypes];

      // For session backlog item mapping, we have loose limits on which fields to show.
      // Textfields (type: string) are difficult to work with, because the jql for these
      // doesn't consistently (eg. some words are ignored). So it's safer just not to use them.)
      const isSessionField = (field) =>
        field.schema?.type === "array"
          ? arrayTypes.includes(field.schema.items)
          : simpleTypes.includes(field.schema?.type);

      const rawFields = await this.getIssueFields(project_id, issue_type_id);

      const numericFields = Object.entries(rawFields)
        .filter(([, field]) => field.schema?.type === "number")
        .map(([key, { name }]) => ({ key, name }));

      const sessionFields = Object.entries(rawFields)
        .filter(([, field]) => isSessionField(field))
        .map(([key, { name }]) => ({ field_id: key, name }));

      return { rawFields, numericFields, sessionFields };
    },
    getTeamMappingSources(teamId) {
      return remote.call("get_team_mapping_sources_formatted", [
        connectionID,
        +teamId,
      ]);
    },
    setTeamMappingSources(teamId, sources) {
      return remote.call("set_team_mapping_sources_from_formatted", [
        connectionID,
        +teamId,
        sources,
      ]);
    },

    getArtTeamFieldMapping(artId) {
      return remote.call("get_art_team_field_mapping_formatted", [
        connectionID,
        +artId,
      ]);
    },
    setArtTeamFieldMapping(artId, teamFieldMapping) {
      return remote.call("set_art_team_field_mapping_from_formatted", [
        connectionID,
        +artId,
        teamFieldMapping,
      ]);
    },
    getArtFieldMapping(artId) {
      return remote.call("get_art_field_mapping_formatted", [
        connectionID,
        +artId,
      ]);
    },
    setArtFieldMapping(artId, artFieldMapping) {
      return remote.call("set_art_field_mapping_from_formatted", [
        connectionID,
        +artId,
        artFieldMapping,
      ]);
    },
    getTeamFieldMappingForOneArtSession() {
      return remote.call("get_session_team_field_mapping_formatted", [
        almSessionId,
      ]);
    },
    setTeamFieldMappingForOneArtSession(teamFieldMapping) {
      return remote.call("set_session_team_field_mapping_from_formatted", [
        connectionID,
        almSessionId,
        teamFieldMapping,
      ]);
    },

    getArtTeamFieldMappingFields(project_ids) {
      return remote.call("get_art_team_field_mapping_fields", [connectionID], {
        project_ids,
      });
    },
    getTeamMappingFields(project_ids) {
      return remote.call("get_team_mapping_fields", [connectionID], {
        project_ids,
      });
    },
    getTeamDefaultFields(project_ids) {
      return remote.call("get_team_default_fields", [connectionID], {
        project_ids,
      });
    },
    getMapping: () => {
      return remote.call("get_board_mapping", [connectionID]);
    },
    getProjects: () => {
      return remote
        .call("get_projects", [connectionID])
        .then((projects) =>
          projects.map((project) => ({ id: project.id, name: project.name }))
        );
    },
    getBoards(projectId) {
      return remote.call("get_boards", [connectionID], {
        project_id: projectId,
      });
    },
    getWebhookUrl: () => {
      return remote.call("get_webhook_endpoint", [], {
        connection_id: connectionID,
      });
    },
    getIssueTypes,
    getPortfolioIssueTypes: getIssueTypes,
    checkLinkType: (link) => {
      let from_projects = link.from.projects.map((p) => p.id);
      let to_projects = link.to.projects.map((p) => p.id);
      return remote.call("check_link_type", [
        connectionID,
        link.from.almId,
        link.to.almId,
        link.type.link_type,
        from_projects,
        to_projects,
      ]);
    },
    getAlmTypes: () => {
      return remote
        .call("session.get_alm_types", [almSessionId])
        .then((almTypes) =>
          almTypes.map((almType) => ({
            id: almType.id,
            issue_type: almType.issue_type,
            project_id: almType.project_id,
            wsjf: () => almType.jira_WSJF_field,
            jira_session_fields: almType.jira_session_fields || [],
          }))
        );
    },
    getTeamBoardAlmType: (almType) => {
      return almType ? { issueType: almType.issue_type } : {};
    },
    getBacklogBoardAlmType: (_sticky, almType) => {
      return almType
        ? {
            selectedField: almType.wsjf(),
            jiraSessionFields: almType.jira_session_fields,
          }
        : {};
    },
    getLinkTypes: () => {
      return remote
        .call("session.get_link_types", [almSessionId])
        .then((almLinkTypes) =>
          almLinkTypes.map((almLinkType) => ({
            pi_link_type_id: almLinkType.pi_link_type_id,
            issue_link_id: almLinkType.issue_link_id,
            id: almLinkType.id,
            type: almLinkType.type,
          }))
        );
    },
    deleteLinkType: (link) => {
      return remote.call("session.delete_link_type", [link.almId]);
    },
    updateLinkType: (link) => {
      return remote.call("session.update_link_type", [
        link.almId,
        {
          issue_link_id: link.type.issue_link_id,
          type: link.type.link_type,
        },
      ]);
    },
    createLinkType: (link) => {
      return remote.call("session.create_link_type", [
        almSessionId,
        link.id,
        link.type.link_type,
        link.type.issue_link_id,
      ]);
    },
    getTags: () => {
      return Promise.resolve([]);
    },
    loadSourceSprints: async (source, isCreating) => {
      if (!source.board_id) {
        return;
      }
      const res = await remote.call("get_sprints", [], {
        connection_id: connectionID,
        board_id: source.board_id,
      });
      const noSync = [{ name: "Don't sync to Jira", id: null }];
      const createSprint = isCreating
        ? [{ name: "Create new Sprint for me in Jira", id: -2 }]
        : [];
      source.sprints = [...noSync, ...createSprint, ...extractSprints(res)];

      function extractSprints(res) {
        if (typeof res === "object" && "success" in res) {
          if (!res.success) {
            throw new Error(res.error || "Could not load sprints");
          }
          return res.sprints || [];
        }
        return res;
      }
    },
    setSourceSprint: async (source, sessionSource) => {
      source.sprint = sessionSource.sprint ?? null;
      if (source.sprint) {
        await checkIfSelectedSprintInAvailableSprints(source);
      }
    },
    checkIssueTypes: (issueTypeId, projectIds) => {
      return remote.call("check_issue_type_fields", [], {
        connection_id: connectionID,
        issue_type_id: issueTypeId,
        projects: projectIds,
      });
    },
    getEpicIssueType: () => {
      return remote.call("get_epic_issue_type", [connectionID]);
    },
    getCustomEpicIssueTypeId: () => {
      return remote.call("get_custom_epic_issue_type", [connectionID]);
    },
    setCustomEpicIssueTypeId: (issueTypeId) => {
      return remote.call("set_custom_epic_issue_type", [
        connectionID,
        issueTypeId,
      ]);
    },
    checkProjectPermissions(projectId) {
      return remote.call("check_project_permissions", [
        connectionID,
        projectId,
      ]);
    },
    checkProjectPermissionsForArt(projectId) {
      return remote.call("check_project_permissions_for_art", [
        connectionID,
        projectId,
      ]);
    },
    checkAllProjectPermissions() {
      return remote.call("check_all_project_permissions", [connectionID]);
    },
    checkAllProjectPermissionsForArt() {
      return remote.call("check_all_project_permissions_for_art", [
        connectionID,
      ]);
    },
    createTestIssue({
      project_id,
      issue_type_id,
      default_fields,
      team_fields,
      art_fields,
      art_team_fields,
    }) {
      return remote.call("create_test_issue_from_client_format", [
        connectionID,
        project_id,
        issue_type_id,
        default_fields,
        team_fields,
        art_fields,
        art_team_fields,
        true,
      ]);
    },
    checkIssueType(issueTypeId, artIds) {
      return remote.call("check_issue_type_for_arts", [
        connectionID,
        issueTypeId,
        artIds,
      ]);
    },
    finishOauth(hostname) {
      return remote.call("finish_oauth", [], {
        hostname,
        connection_id: connectionID,
      });
    },
    getOauthUrl(hostname) {
      return remote.call("get_oauth_url", [], {
        hostname,
        connection_id: connectionID,
      });
    },
    getOauthPublicKey() {
      return remote.call("get_oauth_pubkey");
    },
    getOauthConsumerKey() {
      return remote.call("get_oauth_consumer_key", [], {
        connection_id: connectionID,
      });
    },
    setPassword(password) {
      return remote.call("set_password", [], {
        password,
        connection_id: connectionID,
      });
    },
  };

  async function checkIfSelectedSprintInAvailableSprints(source) {
    if (source.sprints.length > 0) {
      // exclude sprints that caught error in getJiraSprintsForUser
      const available = source.sprints.some((s) => s.id === source.sprint);
      if (!available) {
        let name;
        try {
          const sprint = await remote.call("get_sprint", [], {
            connection_id: connectionID,
            sprint_id: source.sprint,
          });
          name = sprint.name
            ? sprint.name + " [closed]"
            : source.sprint + " [not found]";
        } catch (err) {
          void captureExceptionWithContext(err, { source });
          name = source.sprint + " [problem]";
        }
        source.sprints.push({ id: source.sprint, name });
      }
    }
  }
}

import { createApiClient, handleRequest } from "@/services/apiClient";
import { downloadFile } from "@/utils";

const baseURL = "v1/users";

export class UserService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getUser(user_id) {
    return handleRequest(
      () => this.apiClient.get(`${baseURL}/${user_id}`),
      (rsp) => mapUser(rsp.data.data)
    );
  }

  async getCurrentUser() {
    return handleRequest(
      () => this.apiClient.get(`${baseURL}/me`),
      (rsp) => mapUser(rsp.data.data)
    );
  }

  async getTeams(id) {
    return handleRequest(() => this.apiClient.get(`${baseURL}/${id}/teams`));
  }

  async getOrgEntities(id) {
    return handleRequest(() =>
      this.apiClient.get(`${baseURL}/${id}/org_entities`)
    );
  }

  async getUsers() {
    return handleRequest(
      () => this.apiClient.get(`${baseURL}`),
      (rsp) => rsp.data.data.results?.map(mapUser)
    );
  }

  async createUser(name, email, role) {
    return handleRequest(() =>
      this.apiClient.post(`${baseURL}`, { name, email, role })
    );
  }

  /**
   * Updates a user's information
   * @param {string} userId - The ID of the user to update
   *
   * @param {Object} data - The user data to update
   * @param {string} [data.email] - The user's email address
   * @param {string} [data.name] - The user's name
   * @param {string} [data.role] - The user's role
   * @param {string} [data.preferredLanguage] - The user's preferred language
   */
  async updateUser(userId, data) {
    const { preferredLanguage, ...rest } = data;
    if (preferredLanguage) {
      rest.preferred_language = preferredLanguage;
    }

    return handleRequest(() =>
      this.apiClient.patch(`${baseURL}/${userId}`, rest)
    );
  }

  /**
   * Updates the properties of the currently logged-in user
   *
   * @param {Object} data - The user data to update
   * @param {string} [data.name] - The user's name
   * @param {string} [data.preferredLanguage] - The user's preferred language
   */
  async updateMe(data) {
    const { preferredLanguage, ...rest } = data;
    if (preferredLanguage) {
      rest.preferred_language = preferredLanguage;
    }

    return handleRequest(() => this.apiClient.patch(`${baseURL}/me`, rest));
  }

  async deleteUser(id) {
    return handleRequest(() => this.apiClient.delete(`${baseURL}/${id}`));
  }

  async search(query, role) {
    return handleRequest(() =>
      this.apiClient.get(`${baseURL}/search`, { params: { query, role } })
    );
  }

  async changePassword(id, password) {
    return handleRequest(() =>
      this.apiClient.patch(
        `${baseURL}/${id}/password`,
        { password },
        { timeout: 20000 }
      )
    );
  }

  async resetPassword(userId) {
    return handleRequest(() =>
      this.apiClient.get(`${baseURL}/${userId}/forgot-password`, {
        timeout: 20000,
      })
    );
  }

  async exportUsers() {
    const path = `/${baseURL}/export`;
    const res = await this.apiClient.get(path, {
      responseType: "arraybuffer",
    });

    downloadFile("users_export.xlsx", res.data);
  }
}

export function mapUser(serverUser) {
  if (serverUser) {
    return {
      id: serverUser.id,
      company: serverUser.company,
      role: serverUser.role,
      name: serverUser.name,
      email: serverUser.email,
      imageUrl: serverUser.image_url,
      color: serverUser.color,
      hash: serverUser.hash,
      preferredLanguage: serverUser.preferred_language,
      permissions: serverUser.permissions,
    };
  }
}

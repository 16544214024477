import { createApiClient, handleRequest } from "@/services/apiClient";

const baseURL = "v1/roles";

export class RoleService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getRoles() {
    return handleRequest(() => this.apiClient.get(baseURL));
  }
}

import { createApiClient, handleRequest } from "@/services/apiClient";

const root = "v1/companies";
const endpoints = {
  settings: (companyID) => `${root}/${companyID}/settings`,
  license: (companyID) => `${root}/${companyID}/license`,
  theme: (companyID) => `${root}/${companyID}/theme`,
  passwordRules: (companyID) => `${root}/${companyID}/password-rules`,
};

export class CompanyService {
  constructor(authAPIUrl, companyID) {
    this.apiClient = createApiClient(authAPIUrl);
    this.companyID = companyID;
  }

  async getLicense() {
    const path = endpoints.license(this.companyID);
    return handleRequest(() => this.apiClient.get(path));
  }

  async getPasswordRules() {
    const path = endpoints.passwordRules(this.companyID);
    return handleRequest(() => this.apiClient.get(path));
  }

  async getTheme() {
    const path = endpoints.theme(this.companyID);
    return handleRequest(() => this.apiClient.get(path));
  }

  async setTheme(name) {
    const path = endpoints.theme(this.companyID);
    return handleRequest(() => this.apiClient.patch(path, { name }));
  }

  async getSettings() {
    const path = endpoints.settings(this.companyID);
    return handleRequest(
      () => this.apiClient.get(path),
      (res) => ({
        autoLogoutAfter: res.data?.data?.auto_logout_after,
      })
    );
  }

  async setSettings(settings) {
    const path = endpoints.settings(this.companyID);
    return handleRequest(() => this.apiClient.post(path, settings));
  }
}

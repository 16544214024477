<template>
  <base-modal
    :title="title"
    :show-close="false"
    :is-open="isOpen"
    description=""
  >
    <div v-if="inProgress">
      <page-loading />
    </div>
    <div v-else>
      <p v-if="errorMessage" class="warning-color">
        <code>
          {{ errorMessage }}
        </code>
      </p>
      <base-button @click="close">Close</base-button>
    </div>
  </base-modal>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal";
import PageLoading from "@/components/PageLoading";

export default {
  name: "ReloadWorkspaceModal",
  components: {
    BaseModal,
    BaseButton,
    PageLoading,
  },
  props: {
    errorMessage: { type: String, required: false, default: null },
    done: { type: Boolean, required: true },
    isOpen: { type: Boolean, required: true },
  },
  computed: {
    inProgress() {
      return !this.done;
    },
    title() {
      if (this.done && this.errorMessage) {
        return "Reloading status and workflows failed.";
      }
      if (this.done) {
        return "Status and workflows reloaded. You can close this dialog.";
      }
      // loading
      return "Reloading status and workflows. Please do not close this dialog.";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_colors.scss";

.warning-color {
  color: $red;
}
</style>
